import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import { SRLWrapper } from 'simple-react-lightbox';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import Dialog from 'ls-common-client/src/components/Dialog';
import Text from 'ls-common-client/src/components/Text';
import { Comment } from '../../../../../../images';
import { AppContext } from '../../../../../../context';
import { Button, FormRow } from '../../../../../common';
import resizeImage from '../../../../../../lib/resizeImage';

const StyledImageContainer = styled(Container)`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const StyledLink = styled.a`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
`;

const StyledImage = styled.img`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const StyledRemoveButton = styled(EmptyButton)`
  position: absolute;
  z-index: 1;
  right: -8px;
  top: -6px;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 100%;
  padding: 2px 0 0 0;
  justify-content: center;
  align-items: center;
`;

const mutation = gql`
  mutation RemoveReviewAssets($id: String!) {
    removeReviewAsset(id: $id) {
      success
    }
  }
`;

const options = {
  settings: {
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsSize: ['100px', '100px'],
  },
};

const Assets = ({ assets }) => {
  const {
    reviews: { fetchReviews },
  } = useContext(AppContext.Context);
  const [images, setImages] = useState(assets);
  const [show, setShow] = useState();
  const [imageId, setImageId] = useState();

  const [mutate, { loading }] = useMutation(mutation, {
    onCompleted: () => {
      setShow(false);
      setImages(images.filter(item => item.node.id !== imageId));
      fetchReviews();
    },
  });

  const removeImage = useCallback(async () => {
    await mutate({
      variables: {
        id: imageId,
      },
    });
  }, [imageId]);

  if (images && !!images.length)
    return (
      <Container marginTop="small" marginBottom="small">
        <FormRow icon={<Image src={Comment} alt="comment" />} display="flex">
          <SRLWrapper options={options}>
            {images.map(({ node: { id, url } }) => (
              <StyledImageContainer>
                <StyledRemoveButton
                  type="button"
                  onClick={() => {
                    setImageId(id);
                    setShow(true);
                  }}
                >
                  <Icon
                    iconSize="large"
                    className="ls-icon icon-generalxsmall"
                  />
                </StyledRemoveButton>
                <StyledLink
                  key={id}
                  href={resizeImage(url, {
                    width: '800',
                    height: '800',
                  })}
                >
                  <StyledImage
                    src={resizeImage(url, {
                      width: '100',
                      height: '100',
                      fit: 'cover',
                    })}
                    width={100}
                    height={100}
                    alt="url"
                    srl_gallery_image
                  />
                </StyledLink>
              </StyledImageContainer>
            ))}
          </SRLWrapper>
        </FormRow>
        <Dialog
          show={!!show && !!imageId}
          header={<div />}
          padding="medium"
          minWidth="330px"
          maxWidth="330px"
          minHeight="177px"
        >
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="140px"
          >
            <Container
              display="flex"
              justifyContent="space-between"
              marginBottom="normal"
            >
              <Text
                width="120px"
                flex="1 0 auto"
                fontSize="medium"
                fontWeight="600"
              >
                Are you sure you want to delete that photo?
              </Text>
            </Container>
            <Container display="flex">
              <Button
                rounded
                onClick={() => setShow(false)}
                type="button"
                width="auto"
                minWidth="86px"
                fontSize="small"
              >
                No
              </Button>
              <Button
                primary
                marginLeft="auto"
                onClick={() => removeImage()}
                rounded
                width="177px"
                minWidth="177px"
                type="button"
                fontSize="small"
              >
                {loading ? `Deleting...` : `Yes, delete`}
              </Button>
            </Container>
          </Container>
        </Dialog>
      </Container>
    );

  return '';
};

Assets.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape({})),
};

Assets.defaultProps = {
  assets: [],
};

export default Assets;
